@font-face {
    font-family: 'Poppins-Regular';
    src: url('../fonts/Poppins-Regular.ttf');
}
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.ttf');
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.ttf');
}
.clear::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    clear: both;
}
html {
    word-break: break-word;
}
@keyframes shake {
    /*10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }*/
}
@-webkit-keyframes tracking-in-expand {
	0% {
		letter-spacing: -.5em;
		opacity: 0
	}

	40% {
		opacity: .6
	}

	100% {
		opacity: 1
	}
}

@keyframes tracking-in-expand {
	0% {
		letter-spacing: -.5em;
		opacity: 0
	}

	40% {
		opacity: .6
	}

	100% {
		opacity: 1
	}
}
@keyframes ant-skeleton-loading {
	0% {
        background-position: 100% 50%;
	}

	100% {
		background-position: 0 50%;
	}
}
.shine {
    background: linear-gradient(90deg, var(--ant-skeleton-gradient-from-color) 25%, var(--ant-skeleton-gradient-to-color) 37%, var(--ant-skeleton-gradient-from-color) 63%);
    background-size: 400% 100%;
    animation-name: ant-skeleton-loading;
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
$anim-duration: 5; // in seconds
$anim-speed: 0.65; // in seconds
:root {
    --regular-font: 'Poppins-Regular';
    --bold-font: 'Poppins-Bold';
    --semi-font: 'Poppins-SemiBold';
    --pure-black-color: #000000;
    --pure-white-color: #ffffff;
    --gray-color: #A39AB9;
    --darkblue-color: #000000;
    --warn-color: #DB3939;
    --pure-white-bgdcolor: #ffffff;
    --pure-black-bdgcolor: #000000;
    --transparent-bgdcolor: transparent;
    --pure-black-bordercolor: #000000;
    --scroll-barcolor: #F8F8FA;
    --scroll-thumbcolor: #e4e4e4;
    --icon-bgd: center center no-repeat;

    --ant-skeleton-gradient-from-color: rgba(0, 0, 0, 0.06);
    --ant-skeleton-gradient-to-color: rgba(0, 0, 0, 0.15);
    
    --shine-degree: 120deg;
    --shine-color: rgba(255, 255, 255, 0.6);
    --shine-effect: linear-gradient(
        var(--shine-degree),
        transparent,
        var(--shine-color),
        transparent
    );
    --shine-transition: all #{$anim-speed}s ease-in-out;
}
.btn-hover {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--shine-effect);
      left: -100%;
      transition: var(--shine-transition);
    }
  
    &:hover {
      &::before {
        left: 100%;
      }
    }
}
ul, h1, h2, h3, h4, h5, p {
    padding: 0;
    margin: 0;
}
li {
    list-style: none;
}
.scrollable {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: var(--scroll-barcolor);
        border-radius:2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-thumbcolor);
        border-radius: 2px;
    }
}
.ant-input-status-error {
    border-color: #ff4d4f !important;
}
.ant-form-item-explain-error {
    font-size: 12px;
    font-family: var(--regular-font);
}
.ant-form-item {
    margin-bottom: 20px !important;
}
.simpleWrp {
    .ant-modal-content {
        background-color: #ffffff;
        background-clip: padding-box;
        border-radius: 20px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        pointer-events: auto;
        padding: 32px;
    }
    .ant-modal-footer {
        display: none !important;
    }
}
.ant-pagination .ant-pagination-item {
    min-width: 30px !important;
    height: 30px !important;
    border: none !important;
    font-size: 14px;
    color: #313131;
    font-family: var(--regular-font);
    border-radius: 15px !important;
    &.ant-pagination-item-active {
        font-weight: normal !important;
        color: #ffffff !important;
        background-color: #898174 !important;
        a {
            color: #ffffff !important;
        }
    }
}
.ant-pagination-item-link {
    border-radius: 15px !important;
}
.ant-input-data-count {
    position: relative;
    top: 3px;
    // right: 5px;
    font-size: 18px;
    color: #7F7F7F;
    font-family: var(--regular-font);
}